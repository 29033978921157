<template>

<div >
  <van-nav-bar
      title="方安物业缴费系统"
      :left-arrow="isarrow"  />
 <h1></h1>

<div>
  <van-cell-group inset>

    <van-field
        value="淄博方安商务运营服务有限公司"
        label="缴费机构" left-icon="shop-o" readonly
    />
    <van-field
        value="方安物业"
        label="简称" left-icon="setting-o" readonly
    />
    <van-field
        v-model="no"
        placeholder="请输入缴纳费用户号或表号"
        label="户号或表号" left-icon="contact"
    />

  </van-cell-group>
  <h2 class="van-doc-demo-block__title"></h2>

  <h2 class="van-doc-demo-block__title"></h2>
  <h2 class="van-doc-demo-block__title"></h2>
  <van-cell-group inset class="jaofei">
  <van-button type="primary" @click="querygym" size="large">查询</van-button>
  </van-cell-group>
</div>
  <div class="van-contact-list__bottom">

  </div>
</div>

</template>

<script>
import {Button, Cell, CellGroup, List, NavBar, ContactList, Tag, Form, Field, Dialog} from 'vant';
import {getUrlCode, getOpenid} from '@/api/wxmppay'
//import {getUnifiedorderResult} from "@/api/wxmppay"
//import wx from 'weixin-js-sdk'
import {  setOpenid,removeOpenid } from '@/utils/auth'



export default {
  name: "querybill",

  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]:  CellGroup,
    [List.name]:List,
    [NavBar.name]:NavBar,
    [ContactList.name]:ContactList,
    [Tag.name]:Tag,
    [Form.name]: Form,
    [Field.name]:Field,
    [Dialog.name]: Dialog

  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      isarrow: false,
      code:'',
      query:{
        code: "",
      },
      data:{
        openid:""
      },
      no:""
    };
  },
  created() {
    //Dialog.alert(this.$route.query.code);
    this.onLoad()
  },
  methods: {
    onLoad() {
      //Dialog.alert(this.$route.query.code);
      console.log(this.$route.query.code==undefined)
      if(this.$route.query.code==undefined){

       getUrlCode().then(res => {
         console.log(res)
         //window.open(res,"_blank");
          location.href =res
       })
     }else{
      this.code=this.$route.query.code;
      this.query.code=this.code
      getOpenid(this.query).then(res=>{
        this.data.openid=res.Openid
        this.$store.commit("SET_OPENID",res.Openid)
        removeOpenid()
        setOpenid(res.Openid)
        console.log(this.$store.state.openid)

      })
     }
    //获取openid并保存
      //getOpenid().then{}
    },
    querygym(){
      if(this.no==""){
        Dialog.alert({
          message: '没有填写户号或电表号',
        }).then(() => {
          // on close
          return ;
        });
        return ;
      }
      this.$router.push({
        path: '/pay',
        query: {no: this.no}

      })
    }
  },
}

</script>

<style scoped>
.paybg{background: #1677ff;}
.jiaofei{margin-top: 15px;}
</style>